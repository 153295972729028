<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/layout/Footer.vue";
import parseJwt from "@/mixins/decodeToken";
export default {
  name: "app",
  components: {
    Footer,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  async created() {
    let token = localStorage.getItem("app-token");
    if (token) {
      let parsedToken = parseJwt(token);
      this.$store.commit("login/setUserInfo", parsedToken);
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
*::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    background-color: rgba(0,0,0 , 0.02);
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0,0,0 , 0.3);
}
</style>
