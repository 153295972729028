<template>
  <div class="gmv-login">
    <b-container class="gmv-login__card">
      <b-row>
        <b-col sm="12" md="12" lg="12">
          <b-row>
            <b-col class="img-col" sm="12" md="6" lg="6">
              <div class="gmv-login-img">
                <img alt="GMV Logo" src="@/assets/Logo_GrupoMV.png" />
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="6" class="card-container">
              <b-row align-h="center">
                <b-col>
                  <h1>Iniciar sesión</h1>
                  <h6>Ingrese sus credenciales para ingresar</h6>
                </b-col>
              </b-row>

              <b-row align-h="center">
                <b-col class="input-col">
                  <el-input
                    placeholder="Usuario"
                    autocomplete="off"
                    v-model="login.username"
                    @keyup.enter.native="getToken"
                  >
                    <el-button
                      class="icon-input"
                      slot="prepend"
                      icon="el-icon-user-solid"
                      style="background-color: #d32a2d; color: white"
                    ></el-button>
                  </el-input>
                </b-col>
              </b-row>

              <b-row align-h="center">
                <b-col class="input-col">
                  <el-input
                    placeholder="Contraseña"
                    type="password"
                    v-model="login.password"
                    @keyup.enter.native="getToken"
                  >
                    <el-button
                      class="icon-input"
                      slot="prepend"
                      icon="el-icon-lock"
                      style="background-color: #d32a2d; color: white"
                    ></el-button>
                  </el-input>
                </b-col>
              </b-row>

              <b-row align-h="center">
                <b-col class="input-col">
                  <el-button
                    class="button-validate"
                    type="primary"
                    @click="getToken"
                    :loading="isLoading"
                    icon="el-icon-right"
                    >Verificar e ingresar</el-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import parseJwt from "@/mixins/decodeToken";
export default {
  name: "login",
  data() {
    return {
      login: {
        username: null,
        password: null,
      },
      isLoading: false,
    };
  },
  components: {},
  // created() {
  //   let token = localStorage.getItem("app-token");
  //   if (token) {
  //     let parsedToken = parseJwt(token);
  //     this.$store.commit("login/setUserInfo", parsedToken);
  //   }
  // },
  methods: {
    async getToken() {
      this.isLoading = true;
      let result = await this.$store.dispatch("login/getToken", this.login);
      if (result) {
        let userInfo = parseJwt(result.data.access_token);
        if (result.status == 200 && userInfo.scopes[0] == 1) {
          this.$router.push({ name: "SelectBank" });
        } else if (result.status == 200 && userInfo.scopes[0] == 2) {
          this.$router.push({ name: "SelectBank" });
        } else if (result.status == 200 && userInfo.scopes[0] == 3) {
          this.$router.push({ name: "SelectBank" });
        } else if (result.status == 200 && userInfo.scopes[0] == 4) {
          this.$router.push({ name: "SelectBank" });
        }
        this.$store.commit("login/setUserInfo", userInfo);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.input-col {
  margin: 10px 0px;
  display: flex;
  justify-content: center;
}
.gmv-login {
  width: 100%;
  height: 94vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 94.7vh;
  background-image: url("../../assets/background4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(80, 80, 80, 0.65);
}
::v-deep .el-input-group {
  line-height: normal;
  display: inline-table;
  width: 70%;
  border-collapse: separate;
  border-spacing: 0;
}
::v-deep .el-button .icon-input {
  background-color: $color-primary-company;
  color: white;
}
::v-deep .el-input-group--prepend .el-input__inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-align: center;
}
.gmv-login-container {
  box-shadow: 0.625rem 0.625rem 0.625rem 0.063rem rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  background-color: white;
  padding: 7.313rem;
  width: 70rem;
  &__form {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__nav {
    display: flex;
    justify-content: center;
    color: $color-primary-company;
    cursor: pointer;
  }
  &__nav:hover {
    color: #c02929;
  }
}
.img-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-validate {
  width: 70%;
}
</style>
