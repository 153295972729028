import Vue from "vue";
import Vuex from "vuex";
import login from "./modules/login";
import upload from "./modules/upload";
import download from "./modules/download";
import processBase from "./modules/processBase";
import users from "./modules/users";
import updatePassword from "./modules/updatePassword";
import usersPerBanks from "./modules/usersPerBanks";
import banks from "./modules/banks";
import files from "./modules/files";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    upload,
    download,
    processBase,
    users,
    updatePassword,
    usersPerBanks,
    banks,
    files,
  },
});
