import axios from "axios";
import swal from "sweetalert";
import { tokenExpired } from "@/api/errorMessages";
export default {
  namespaced: true,
  state: {
    filesBancolombia: null,
    filesSantander: null,
    processInfo: null,
  },
  actions: {
    async uploadFilesSantander(_, payload) {
      let params = {};
      params.telefonos = false;
      params.asignaciones = false;
      params.actualizaciones = false;
      params.desasignaciones = false;
      params.campaña = payload.campaña;
      for (let property in params) {
        if (property == payload.selectedOption) {
          params[property] = true;
        }
      }
      let response;
      try {
        response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/santander`,
          payload.data,
          {
            params: params,
          }
        );
        if (response.status == 201) {
          swal({
            title: `Archivo subido correctamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `Error al subir archivos`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
        if (error.response.status == 400) {
          swal({
            title: `Error`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
    async uploadFilesBancolombia(_, payload) {
      let params = {};
      params.contener = false;
      params.revolvente = false;
      params.desmontes = false;
      params.status = false;
      params.rechazos_simulacion = false;
      for (let property in params) {
        if (property == payload.selectedOption) {
          params[property] = true;
        }
      }
      let response;
      try {
        response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/bancolombia`,
          payload.body,
          {
            params: params,
          }
        );
        if (response.status == 201) {
          swal({
            title: `Archivo subido correctamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `Error al subir archivos`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
        if (error.response.status == 400) {
          swal({
            title: `Error`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
    async uploadFilesBbva(_, payload) {
      let params = {};
      params.pre_aprobados = false;
      for (let property in params) {
        if (property == payload.selectedOption) {
          params[property] = true;
        }
      }
      let response;
      try {
        response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/bbva`,
          payload.data,
          {
            params: params,
          }
        );
        if (response.status == 201) {
          swal({
            title: `Archivo subido correctamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `Error al subir archivos`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
        if (error.response.status == 400) {
          swal({
            title: `Error`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
  },
};
