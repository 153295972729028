import axios from "axios";
/* import swal from "sweetalert"; */
import { tokenExpired } from "@/api/errorMessages";
export default {
  namespaced: true,
  state: {
    download: null,
  },
  mutations: {
    setDownload(state, newDownload) {
      state.download = newDownload;
    },
  },
  actions: {
    async getDownload(_, params) {
      /* let config = { responseType: 'blob' }; */
      let response;
      try {
        response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `/api/bucket/files/download`,    
          {
            params: params,
            responseType: 'blob'
          }
        );
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `No se encuentra el archivo`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
  },
};