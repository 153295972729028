import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/login/Index.vue";
import ValidatePath from "@/mixins/validatePath"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    redirect: { name: "SelectBank" },
    component: () => import("@/views/layout/Index.vue"),
    children: [
      {
        path: "select-bank",
        name: "SelectBank",
        component: () => import("@/views/home/Index.vue"),
      },
      {
        path: "processed/:bankName",
        name: "ProcessedFiles",
        component: () => import("@/views/processedFiles/Index.vue"),
        beforeEnter: (to, from, next) => {
          const validation = ValidatePath(to.params.bankName)
          if (!validation) {
            next("/home")
            return
          }
          next()
        },
      },
      {
        path: "reported/:bankName",
        name: "ReportedFiles",
        component: () => import("@/views/reportedFiles/Index.vue"),
        beforeEnter: (to, from, next) => {
          const validation = ValidatePath(to.params.bankName)
          if (!validation) {
            next("/home")
            return
          }
          next()
        }
      },
      {
        path: "users-list",
        name: "UsersList",
        component: () => import("@/views/admin/Index.vue"),
      },
      {
        path: "banks-list",
        name: "BankList",
        component: () => import("@/views/adminBanks/Index.vue"),
      }
    ],
  },
  {
    path: "/upload",
    name: "Upload",
    component: () => import("@/views/layout/Index.vue"),
    children: [
      {
        path: ":bankName",
        name: "Uploader",
        component: () => import("@/views/uploader/Index.vue"),
      },
    ],
  },
  {
    path: "/user-password",
    name: "UserPassword",
    component: () => import("@/views/layout/Index.vue"),
    children: [
      {
        path: "users",
        name: "Users",
        component: () => import("@/views/user/Index.vue"),
      },
    ],
  },
  {
    path: "*",
    component: () => import('@/views/notFound/Index.vue'),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
