import axios from "axios";
import swal from "sweetalert";
import { tokenExpired } from "@/api/errorMessages";
export default {
  namespaced: true,
  state: {
    processBase: null,
  },
  actions: {
    async postFinalBase(_, payload) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/bancolombia/final-base`,
          payload
        );
        if (response.status == 200) {
          swal({
            title: `Proceso base final enviado exitosamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        /*   if (error.response.status == 422) {
          swal({
            title: `Proceso pendiente de realizarse`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        } */
      }
    },
    async postBexBase(_, payload) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/bancolombia/bex-base`,
          payload
        );
        if (response.status == 200) {
          swal({
            title: `Proceso base bex enviado exitosamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        /*   if (error.response.status == 422) {
          swal({
            title: `Proceso pendiente de realizarse`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        } */
      }
    },
    async postGenerateTxt(_, payload) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE +
            `/api/bancolombia/generate-txt`,
          payload
        );
        if (response.status == 200) {
          swal({
            title: `Proceso generate txt enviado exitosamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        /*   if (error.response.status == 422) {
          swal({
            title: `Proceso pendiente de realizarse`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        } */
      }
    },
    async postTelephoneBase(_, payload) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/santander/base-telefonos`,
          payload
        );
        if (response.status == 200) {
          swal({
            title: `Proceso base telefonos realizado exitosamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        /*   if (error.response.status == 422) {
          swal({
            title: `Proceso pendiente de realizarse`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        } */
      }
    },
    async postAssigmentBase(_, payload) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/santander/Asignacion`,
          payload
        );
        if (response.status == 200) {
          swal({
            title: `Proceso base asignación enviado exitosamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        /*   if (error.response.status == 422) {
          swal({
            title: `Proceso pendiente de realizarse`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        } */
      }
    },
    async postDeallocationBase(_, payload) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/santander/Desassignacion`,
          payload
        );
        if (response.status == 200) {
          swal({
            title: `Proceso base desasignación enviado exitosamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        /*   if (error.response.status == 422) {
          swal({
            title: `Proceso pendiente de realizarse`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        } */
      }
    },
    async postComercialBase(_, payload) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/bbva/base-comercial`,
          payload
        );
        if (response.status == 200) {
          swal({
            title: `Proceso base comercial realizado exitosamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        /*   if (error.response.status == 422) {
          swal({
            title: `Proceso pendiente de realizarse`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        } */
      }
    },
  },
};
