function validatePath(bankName) {
    const banks = JSON.parse(localStorage.getItem("banks"))
    const userScopes = JSON.parse(localStorage.getItem("userScopes"))
    let userBanks = []
    userScopes.forEach(scope => {
        for (let bank of banks) {
            if (scope === bank.id) {
                userBanks.push(bank.name)
            }
        }
    });
    const validation = userBanks.includes(bankName)
    return validation;
}

export default validatePath;