import axios from "axios";
import swal from "sweetalert";
import { tokenExpired } from "@/api/errorMessages";
export default {
  namespaced: true,
  state: {
    files: null,
    filesByType: null,
    filesCount: 0,
  },
  getters: {
    showFilesCount(state) {
      return state.filesCount;
    },
  },
  mutations: {
    setFiles(state, newFiles) {
      state.files = newFiles;
    },
    setFilesByType(state, newFiles) {
      state.filesByType = newFiles;
    },
    setFilesCount(state, count) {
      state.filesCount = count;
    },
  },
  actions: {
    async getFilesCount({ commit }, params) {
      let response;
      try {
        response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `/api/files/count`,
          {
            params: params,
            paramsSerializer: {
              indexes: null,
            },
          }
        );
        if (response.status == 200) {
          commit("setFilesCount", response.data.count);
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        console.log(error.response);
        if (response.status == 404) {
          console.log("No se encuentra archivo");
        }
      }
    },

    async getFiles({ commit }, params) {
      let response;
      try {
        response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `/api/files`,
          {
            params: params,
            paramsSerializer: {
              indexes: null,
            },
          }
        );
        if (response.status == 200) {
          commit("setFiles", response.data);
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        console.log(error.response);
        if (response.status == 404) {
          console.log("No se encuentra archivo");
        }
      }
    },

    async deleteFiles(_, id) {
      let response;
      try {
        response = await axios.delete(
          process.env.VUE_APP_BACK_ROUTE + `/api/files/${id}`
        );
        if (response.status == 204) {
          swal({
            title: `El archivo fue eliminado correctamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `No se pudo eliminar el archivo`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
  },
};
