import axios from "axios";
import swal from "sweetalert";

export default {
  namespaced: true,
  state: {
    token: null,
    userInfo: null
  },
  getters: {
    showToken(state) {
      return state.token;
    },
    showUserInfo(state) {
      return state.userInfo;
    }
  },
  mutations: {
    setToken(state, newToken) {
      state.token = newToken;
    },
    setUserInfo(state, userInfo) {
      localStorage.setItem("userScopes", JSON.stringify(userInfo.scopes))
      state.userInfo = userInfo;
    }
  },
  actions: {
    async getToken({ commit }, payload) {
      const bodyFormData = new FormData();
      bodyFormData.append("username", payload.username);
      bodyFormData.append("password", payload.password);
      let response;
      try {
        response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + "/api/login",
          bodyFormData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        );
        if (response.status === 200) {
          localStorage.setItem("app-token", response.data.access_token);
          commit("setToken", response.data.access_token);
        }
        return response;
      } catch (error) {
        if (error.response.status === 404 || error.response.status === 401) {
          swal({
            title: "Datos incorrectos",
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true
          });
        }
      }
    },
  },
  modules: {}
};
