import swal from "sweetalert";
import router from "@/router";

export function tokenExpired() {
  swal({
    title: "La sesión ha expirado",
    text: "Por favor, iniciar sesión de nuevo",
    icon: "warning",
    buttons: true,
    dangerMode: "true"
  }).then(willDelete => {
    if (willDelete) {
      localStorage.clear();
    }
    router.push({
      name: "Login"
    });
  });
}