import axios from "axios";
/* import swal from "sweetalert"; */
import { tokenExpired } from "@/api/errorMessages";
export default {
  namespaced: true,
  state: {
    relation: null,
  },
  mutations: {
    setRelation(state, newRelations) {
      state.relation = newRelations;
    },
  },
  actions: {
    async getRelation({ commit }) {
      let response;
      try {
        response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `/api/usersbanks`
        );
        if (response.status == 200) {
          commit("setRelation", response.data);
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
      }
    },
    async getBankUser(_, params) {
      let response;
      try {
        response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE +
            `/api/usersbanks/user/${params.user_id}`,
          params
        );
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
      }
    },
  },
};
