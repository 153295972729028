import axios from "axios";
import router from "@/router";

export default function setup() {
  if (router.currentRoute.name !== "Login") {
    axios.interceptors.request.use(
      function(config) {
        const token = localStorage.getItem("app-token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      function(err) {
        return Promise.reject(err);
      }
    );
  }
}
