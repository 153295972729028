import axios from "axios";
import swal from "sweetalert";
import { tokenExpired } from "@/api/errorMessages";
export default {
  namespaced: true,
  state: {
    banks: null,
  },
  getters: {
    showBanks(state) {
      return state.banks;
    }
  },
  mutations: {
    setBanks(state, newBanks) {
      localStorage.setItem("banks", JSON.stringify(newBanks))
      state.banks = newBanks;
    },
    setGetCount(state, newCount) {
      state.count = newCount;
    },
    cleanBanks(state) {
      state.banks = null;
    }
  },
  actions: {
    async getBanks({ commit }) {
      let response;
      try {
        response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `/api/banks`
        );
        if (response.status == 200) {
          commit("setBanks", response.data);
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (response.status == 404) {
          console.log("No se encuentra usuario");
        }
      }
    },
    async getCount({ commit }) {
      let response;
      try {
        response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `/api/banks/count`
        );
        if (response.status == 200) {
          commit("setGetCount", response.data);
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 404) {
          console.log("No se encuentra contador de usuario");
        }
      }
    },
    async postBanks(_, payload) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/banks`,
          payload
        );
        if (response.status == 201) {
          swal({
            title: `Banco registrado correctamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `Banco ya registrado`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
    async deleteBanks(_, params) {
      let response;
      try {
        response = await axios.delete(
          process.env.VUE_APP_BACK_ROUTE + `/api/banks/${params.id}`
        );
        if (response.status == 204) {
          swal({
            title: `Banco fue eliminado correctamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `No se pudo eliminar el banco`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
    async patchBanks(_, payload) {
      let response;
      try {
        response = await axios.patch(
          process.env.VUE_APP_BACK_ROUTE + `/api/banks/${payload.id}`,
          payload
        );
        if (response.status == 204) {
          swal({
            title: `El banco fue actualizado correctamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        console.log(error.response);
        if (error.response.status == 422) {
          swal({
            title: `No se pudo actualizar el banco`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
  },
};
