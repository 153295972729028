import axios from "axios";
import swal from "sweetalert";
import { tokenExpired } from "@/api/errorMessages";
export default {
  namespaced: true,
  state: {
    updatePassword: null,
  },
  actions: {
    async putupdatePassword(_, payload) {
      try {
        let response = await axios.put(
          process.env.VUE_APP_BACK_ROUTE + `/api/login/user`,
          {},
          { params: payload }
        );
        if (response.status == 200) {
          swal({
            title: `Contraseña actualizada correctamente`,
            icon: "success",
          });
        }
        return response;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `Proceso ya realizado`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
  },
};
