import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//Element
import lang from "element-ui/lib/locale/lang/es";
import locale from "element-ui/lib/locale";

// configure language
locale.use(lang);

//Vuelidate
import Vuelidate from "vuelidate";

//Axios interceptos
import axios from "axios";
import interceptorsSetup from "@/api/interceptors";

//BootstrapVue
import "@/plugins/bootstrapVue";
//PrimeVue
import "@/plugins/primeVue.js";
//fontAwesome
import "@/plugins/fontAwesome";
//sweetAlert
import "@/plugins/sweetAlert";
//Element
import "@/plugins/element";

interceptorsSetup();
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.use(Vuelidate);

//Filters
import "@/filters/formatDate";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
